import React, { useState } from "react"
import closeIcon from "../assets/close.png"

const Block = ({ images, onChooseHandler }) => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [isFullScreen, setIsFullscreen] = useState(null)

    const onClickHandler = (img) => {
        onChooseHandler(img)
        setSelectedImage(img)
    }

    return (
        <div className='img-container'>
            {images?.map((img) => (
                <div className='img-block' key={img.id}>
                    <div
                        className={`img-item ${
                            selectedImage?.id === img.id ? "choosed" : ""
                        }`}
                        onClick={() => onClickHandler(img)}
                    >
                        <img src={img.url_preview} alt='pic' />
                    </div>
                    <button
                        className='btn btn-zoom'
                        onClick={() => setIsFullscreen(img)}
                    >
                        Увеличить
                    </button>
                </div>
            ))}
            {isFullScreen && (
                <div className='modal'>
                    <div
                        className='modal-image'
                        style={{
                            backgroundImage: `url(${isFullScreen.url})`,
                        }}
                    ></div>
                    <button
                        className='btn-close'
                        onClick={() => setIsFullscreen(null)}
                    >
                        <img src={closeIcon} alt='close' />
                    </button>
                </div>
            )}
        </div>
    )
}

export default Block
