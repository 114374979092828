import React from "react"

const Thanks = () => {
    return (
        <div className='thanks'>
            <h2>Благодарим за участие! <br/> Вы делаете нас лучше ❤️</h2>
        </div>
    )
}

export default Thanks
