import { useState } from "react"
import "./App.css"
import Block from "./components/Block"
import Thanks from "./components/Thanks"
import Start from "./components/Start"

const refs = [
    [
        {
            id: 1,
            url_preview: "./assets/1thumbnail.jpg",
            url: "./assets/1.jpg",
        },
        {
            id: 2,
            url_preview: "./assets/2thumbnail.jpg",
            url: "./assets/2.jpg",
        },
        {
            id: 3,
            url_preview: "./assets/3thumbnail.jpg",
            url: "./assets/3.jpg",
        },
    ],
    [
        {
            id: 4,
            url_preview: "./assets/4thumbnail.jpg",
            url: "./assets/4.jpg",
        },
        {
            id: 5,
            url_preview: "./assets/5thumbnail.jpg",
            url: "./assets/5.jpg",
        },
        {
            id: 6,
            url_preview: "./assets/6thumbnail.jpg",
            url: "./assets/6.jpg",
        },
    ],
    [
        {
            id: 7,
            url_preview: "./assets/7thumbnail.jpg",
            url: "./assets/7.jpg",
        },
        {
            id: 8,
            url_preview: "./assets/8thumbnail.jpg",
            url: "./assets/8.jpg",
        },
        {
            id: 9,
            url_preview: "./assets/9thumbnail.jpg",
            url: "./assets/9.jpg",
        },
    ],
]
const COUNT_STEPS = 3;
const refsChoosed = []
let result;
function App() {
    const [step, setStep] = useState(-1)
    const [triggerChoosed, setTriggerChoosed] = useState()
    const [disabled, setDisabled] = useState(true)

    const onChooseHandler = (img) => {
        if (step !== 3) {
            refsChoosed[step] = img
            setTriggerChoosed(Math.random())
        } else {
            result = img;
        }
        setDisabled(false)
    }

    const sendStep = () => {
        const data = {};
        data['url'] = window.location.href;
        data['step'] = step;
        data['value'] = refsChoosed[step] ? refsChoosed[step].id : result.id;

        fetch('https://web-30.ru/api/save-check', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
            keepalive: true
        }).then(() => {
            setStep(prev => prev + 1)
            setDisabled(true)
        })
    }

    const showStepData = step > -1 && step <= COUNT_STEPS;
    const showStart = step < 0;
    const showThanks = step > COUNT_STEPS;
    return (
        <div className='references'>
            <div className='block'>
                {showStart && <Start>
                    <div>
                        <button
                            className="btn"
                            onClick={() => setStep(prev => prev + 1)}
                        >Начать</button>
                    </div>
                </Start>}


                {showStepData && <h2>Выберите наиболее понравившееся Вам изображение</h2>}
                {step < COUNT_STEPS && (
                    <Block images={refs[step]} onChooseHandler={onChooseHandler} />
                )}
                {step === COUNT_STEPS && (
                    <Block images={refsChoosed} onChooseHandler={onChooseHandler} />
                )}
                {showThanks && <Thanks />}
                {showStepData && (
                    <div className="footer">
                        <button
                            className="btn"
                            data-upd={triggerChoosed}
                            disabled={disabled}
                            onClick={() => sendStep()}
                        >{step === COUNT_STEPS ? 'Закончить' : 'Продолжить'}</button>
                        <div>{step + 1} / 4</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default App
