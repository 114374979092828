import React from "react"

const Start = (
    {children}
) => {
    return (
        <div className='start'>
            <h2>Какой стиль вам кажется более привлекательным для создания виртуальной переговорной в корпоративной метавселенной?</h2>
            {children}
        </div>
    )
}

export default Start
